import React from 'react';
import { Link , useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../../utils/dates';
import BDClimitDateModal from '../../../modal/communication/BDClimitDateModal';
import BDCpaginationsModal from '../../../modal/communication/BDCpaginationsModal';
import './style.scss';
import { DOMAIN } from '../../../../../config/env';
import Stat from '../../../../../utils/stat';
import useAuth from '../../../../../hooks/useAuth';

/** Prospectus card  */
const BDCprospectusCard = ({ prospectus }) => {

  const { magasin } = useAuth(); // magasins code
  const navigate = useNavigate() // navigate hook

  const handleOrderEntryLink = () => {
      navigate(`/bdc/prospectus/${prospectus.PSP_CProspectus}`)
      Stat.addStat({
        mag_code : magasin.mag_code,
        libelle : prospectus.psp_libelle,
        type: 2,
        prs_cprospectus : prospectus.psp_cprospectus
      })
  }

  const handleShowOldOrder = () => {
    window.open(`${DOMAIN.URL}_fichier/_cmu/_bdcs/Px${prospectus.psp_cprospectus}.PDF`)
    Stat.addStat({
      mag_code : magasin.mag_code,
      libelle : prospectus.psp_libelle,
      type: 3,
      prs_cprospectus : prospectus.psp_cprospectus
    })
  }

  // Function to verify if is valid date
  const isValidDate = (date) => {
    return date && !date.startsWith('0000')
  }

  return (
    <>
      <div className="prospectus-card">
        <div className="prospectus-card__header">
          <h4 className="libelle">{prospectus.psp_libelle}</h4>
        </div>
        <div className="content">
          {prospectus.psp_commentaire && (
            <div className="info">{prospectus.psp_commentaire}</div>
          )}

          <div className="date-list">
            <ul>
              {isValidDate(prospectus.psp_dn1_debut) && (
                <li>
                  <span> Date nationale 1 </span> du{' '}
                  {formatDate(prospectus.psp_dn1_debut)} au{' '}
                  {formatDate(prospectus.psp_dn1_fin)}
                </li>
              ) }
              { isValidDate(prospectus.psp_dn2_debut) && (
                <li>
                  <span> Date nationale 2 </span> du{' '}
                  {formatDate(prospectus.psp_dn2_debut)} au{' '}
                  {formatDate(prospectus.psp_dn2_fin)}
                </li>
              )}
            </ul>
          </div>
        </div>
        <footer>
          <div className="action-wrap">
            <BDCpaginationsModal
              key={'pagination_' + prospectus.psp_cprospectus}
              prospectus={prospectus}
            />
            <BDClimitDateModal
              key={'limitedate_' + prospectus.psp_cprospectus}
              prospectus={prospectus}
            />
            <button
              className='action-button primary-button'
              onClick={handleOrderEntryLink}
            >
              Saisir mes commandes
            </button>
            <div className="action-icon">
              <button
                className="pdf-btn action-link"
                onClick={handleShowOldOrder}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-file-earmark-pdf-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                  <path
                    fillRule="evenodd"
                    d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
                  />
                </svg>
                Ancien BDC

              </button>
              <a
                className="excel-btn action-link"
                href={`${DOMAIN.URL}_include/export_excel.php?fichier=bdc&pro_code=${prospectus.psp_cprospectus}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-file-earmark-excel-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                </svg>
                Liste produits
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default BDCprospectusCard;
